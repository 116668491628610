<template>
  <!--begin::Card-->
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">
      Delivery Charges <br />
      <small
        >Enter the details for your Delivery Charge and Minimum Order Amount
        here. Website delivery charge works based on the distance in miles the
        customer is from your shop. For example, you can set a {{$store.state.auth.currency}}2 charge up to 5
        miles away and then add an additional {{$store.state.auth.currency}}1 per mile on to the charge for
        any customers over that distance.</small
      >
    </h4>
    <div class="form-group" style="display:flex">
      <label>Distance Calculation : </label>&nbsp;&nbsp;&nbsp;
      <b-form-radio v-model="distance_measure" value="miles">Miles</b-form-radio>&nbsp;&nbsp;
      <b-form-radio v-model="distance_measure" value="km">KM</b-form-radio>
    </div>
    <div class="form-group">
      <label>Minimum Order value for Delivery</label>
           <input
            type="text"
            class="form-control form-control-solid form-control-lg"
            v-model="delivery_min_amount"
            v-on:keypress="isNumber($event)"
            placeholder="Delivery Minimum Amount"
          />
           <span class="form-text text-muted">Enter the minimum order value for deliveries, any order less than this value won't be available for delivery.</span>
    </div>
    <div class="form-group">
      <label>Free Delivery Under</label>
      <b-row class="my-1">
        <b-col sm="11">
           <input
            type="text"
            class="form-control form-control-solid form-control-lg"
            v-model="delivery_free_distance"
            v-on:keypress="isNumber($event)"
            placeholder="Delivery Free Distance"
          />
          <span class="form-text text-muted"
            >Enter the distance under which deliveries are free.</span
          >
        </b-col>
        <b-col sm="1">
          <p><b>{{ this.distance_measure }}</b></p>
        </b-col>
      </b-row>
      
    </div>
    <b-button @click="addDistance" style="float:right">Add Distance</b-button>
    <br>
    <div class="form-group" v-for="(cd,index) in cost_distance"  :key="index">
      <label>Cost up to a certain distance</label>
      <b-row class="my-1">
        <b-col sm="4">
          <input
            type="number"
            class="form-control form-control-solid form-control-sm"
            v-model="cd.cost"
            v-on:keypress="isNumber($event)"
          />
        </b-col>
        <b-col sm="1">
          <p>upto</p>
        </b-col>
        <b-col sm="4">
          <input
            type="number"
            class="form-control form-control-solid form-control-sm"
            v-model="cd.distance"
            v-on:keypress="isNumber($event)"
          />
        </b-col>
        <b-col sm="1">
          <p><b>{{ distance_measure }}</b></p>
        </b-col>
         <b-col sm="1">
          <b-button @click="deleteDistance(cd)">X</b-button>
        </b-col>
      </b-row>

      <span class="form-text text-muted">Cost per distance</span>
    </div>
    <div class="form-group">
      <label>Cost per additional distance</label>
      <b-row class="my-1">
        <b-col sm="4">
          <input
            type="text"
            class="form-control form-control-solid form-control-sm"
            v-model="additional_cost"
            v-on:keypress="isNumber($event)"
          />
        </b-col>
        <b-col sm="1">
          <p>per</p>
        </b-col>
        <b-col sm="4">
          <input
            type="text"
            class="form-control form-control-solid form-control-sm"
            v-model="additional_distance"
            v-on:keypress="isNumber($event)"
          />
        </b-col>
        <b-col sm="1">
          <p><b>{{ this.distance_measure }}</b></p>
        </b-col>
      </b-row>
      <span class="form-text text-muted">Enter the delivery price.</span>
    </div>
    <div class="form-group">
      <label>Delivery Max Distance</label>
      <b-row class="my-1">
        <b-col sm="11">
            <input
            type="text"
            class="form-control form-control-solid form-control-lg"
            v-model="delivery_max_distance"
            placeholder="Max Delivery Distance"
            v-on:keypress="isNumber($event)"
          />
          <span class="form-text text-muted"
            >Enter the max delivery distance for orders.</span
          >
        </b-col>
        <b-col sm="1">
          <p><b>{{ this.distance_measure }}</b></p>
        </b-col>
      </b-row>
    </div>
    <div class="form-group">
      <label>Delivery Charge Override By Order Value</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="delivery_charge_override"
        placeholder="Delivery Charge Override By Order Value"
        v-on:keypress="isNumber($event)"
      />
      <span class="form-text text-muted"
        >Enter the order value for which delivery charges become free/zero.
        Leave blank for no override.</span
      >
    </div>
    <button
    v-if=" $hasPermission('manage system settings') "

        :style="{'display': $route.name === 'wizard-delivery-charges-details' ? 'block' : 'none'}"
        @click="save"
        class="btn btn-primary mt-3 mr-4"
      >
        Submit
      </button>
  </div>
  <!--end::Card-->
</template>

<script>
import {
  DELIVERY_CHARGES,
  GET_DELIVERY_CHARGES,
} from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";

export default {
  name: "DeliveryCharge",
  data() {
    return {
      delivery_min_amount: 0,
      delivery_free_distance: "",
      additional_cost: 0,
      additional_distance: "",
      delivery_max_distance: "",
      delivery_charge_override: "",
      distance_measure : 'miles',
      cost_distance : []
    };
  },
  methods: {
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    deleteDistance(obj)
    {
      this.cost_distance.splice(this.cost_distance.indexOf(obj), 1);
    },
    addDistance()
    {
      this.cost_distance.push({
        id: 0,
        cost: 0,
        distance: 0,
      });
    },
    save() {
      this.$store
        .dispatch(DELIVERY_CHARGES, {
          delivery_min_amount: this.delivery_min_amount,
          delivery_free_distance: this.delivery_free_distance,
          additional_cost: this.additional_cost,
          additional_distance: this.additional_distance,
          delivery_max_distance: this.delivery_max_distance,
          delivery_charge_override: this.delivery_charge_override,
          measures : this.distance_measure,
          cost_distance : this.cost_distance
        })
        // go to which page after successfully login
        .then((resp) => {
          // this.form.orders = resp.data;
          if (resp.status === "Success") {
            if  (this.$route.name === 'wizard-delivery-charges-details') {
              Swal.fire("Success", "Delivery Charges updated successfully", "success");
            }
            else{
              this.$emit('validation', true);
              this.$refs.form_submit.click();
            }
          } else {
            this.$emit('validation', false);
            Swal.fire("Error", resp.message, "error");
            return;
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
    cancel() {
      //
    },
    onSubmit() {
      this.save();
    },
    getDeliveryCharges() {
      this.$store
        .dispatch(GET_DELIVERY_CHARGES)
        .then((resp) => {
          if (resp.length) {
            this.delivery_min_amount = resp[0].minimum_order_value_delivery;
            this.delivery_free_distance = resp[0].delivery_free_distance;
            this.additional_cost = resp[0].additional_distance_cost;
            this.additional_distance = resp[0].additional_distance_per_km_mile;
            this.delivery_max_distance = resp[0].delivery_max_distance;
            this.delivery_charge_override = resp[0].delivery_charge_override;
            this.distance_measure = resp[0].distance_measure;
            this.cost_distance = resp[0].costdelivery;
          }
          if (! this.cost_distance.length) {
            this.cost_distance.push({
              id: 0,
              cost: 0,
              distance: 0,
            });
          } 
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDeliveryCharges();

    const form = KTUtil.getById("deliveryChargeForm");
    this.fv = formValidation(form, {
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
};
</script>
